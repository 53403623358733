import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { checkIfLogged } from './middlewares'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, _, next) => {
  if (!to.meta.guestAccess) {
    checkIfLogged(next)
  } else {
    next()
  }
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
