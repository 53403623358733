import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import { ImageResolver } from '@/services/Managers'
import { Toast } from '.'
import DateManager from '@/services/Managers/DateManager'
import { pluralize } from '@/services/functions'

@Component
export default class MiscMixin extends Mixins(Toast) {
  ImageResolver = ImageResolver
  DateManager = DateManager

  pluralize = pluralize

  async copyToClipboard(data, itemCopied) {
    // @ts-ignore
    const copied = await this.$copyText(data)

    if (copied) {
      this.showSuccessToast({
        title: 'Successfully copied!',
        message: `${itemCopied || 'Account ID'} has been copied on your clipboard`
      })
    }

    return copied
  }
}