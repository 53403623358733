const ErrorRoutes = [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'full',
      guestAccess: true
    }
  },
  {
    path: '*',
    redirect: 'error-404'
  }
]

export default ErrorRoutes