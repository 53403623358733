import {
  makeGetRequest,
  makePostRequest,
  makeDeleteRequest
} from './makeRequest'

class GameVersionAPI {
  resynchronize() {
    return makePostRequest('/admin/game-files/update/synchronize')
  }

  getGameFiles() {
    return makeGetRequest('/admin/game-files/update')
  }

  getGameFile(patchId: number) {
    return makeGetRequest(`/admin/game-files/update/${patchId}`)
  }

  deleteGameFile(patchId: number) {
    return makeDeleteRequest(`/admin/game-files/update/${patchId}`)
  }

  getGameVersion(version: number) {
    return makeGetRequest(`/admin/game-files/update/version/${version}`)
  }

  deleteGameVersion(version: number) {
    return makeDeleteRequest(`/admin/game-files/update/version/${version}`)
  }
}

const GameVersion = new GameVersionAPI()

export default GameVersion