export enum EquipementSlotType {
  MainWeapon = 0,
  Armor = 1,
  Hat = 2,
  Gloves = 3,
  Boots = 4,
  SecondaryWeapon = 5,
  Necklace = 6,
  Ring = 7,
  Bracelet = 8,
  Mask = 9,
  Fairy = 10,
  Amulet = 11,
  Sp = 12,
  CostumeSuit = 13,
  CostumeHat = 14,
  WeaponSkin = 15,
  Wings = 16,
  Trophy = 100
}

const slots = [
  { id: EquipementSlotType.MainWeapon, name: 'Main Weapon' },
  { id: EquipementSlotType.Armor, name: 'Armor' },
  { id: EquipementSlotType.Hat, name: 'Hat' },
  { id: EquipementSlotType.Gloves, name: 'Gloves' },
  { id: EquipementSlotType.Boots, name: 'Boots' },
  { id: EquipementSlotType.SecondaryWeapon, name: 'Secondary Weapon' },
  { id: EquipementSlotType.Necklace, name: 'Necklace' },
  { id: EquipementSlotType.Ring, name: 'Ring' },
  { id: EquipementSlotType.Bracelet, name: 'Bracelet' },
  { id: EquipementSlotType.Mask, name: 'Mask' },
  { id: EquipementSlotType.Fairy, name: 'Fairy' },
  { id: EquipementSlotType.Amulet, name: 'Amulet' },
  { id: EquipementSlotType.Sp, name: 'Specialist' },
  { id: EquipementSlotType.CostumeSuit, name: 'Costume Suit' },
  { id: EquipementSlotType.CostumeHat, name: 'Costume Hat' },
  { id: EquipementSlotType.WeaponSkin, name: 'Weapon Skin' },
  { id: EquipementSlotType.Wings, name: 'Wings Skin' },
  { id: EquipementSlotType.Trophy, name: 'Trophy' }
]

export default slots