import { Component, Vue } from 'vue-property-decorator'
import ToastificationContent from '@/components/ToastificationContent.vue'

enum ToastType {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger'
}

@Component
export default class Toast extends Vue {
  showSuccessToast({ title, message = '' }) {
    this.$toast.success({
      component: ToastificationContent,
      props: {
        text: message,
        title,
        icon: 'CheckIcon',
        variant: ToastType.SUCCESS
      }
    })
  }

  showWarningToast({ title, message = '' }) {
    this.$toast.warning({
      component: ToastificationContent,
      props: {
        text: message,
        title,
        icon: 'AlertTriangleIcon',
        variant: ToastType.WARNING
      }
    })
  }

  showErrorToast({ title, message = '' }) {
    this.$toast.error({
      component: ToastificationContent,
      props: {
        text: message,
        title,
        icon: 'XIcon',
        variant: ToastType.DANGER
      }
    })
  }
}