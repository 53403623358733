import {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  makeDeleteRequest
} from './makeRequest'

class AccountAPI {
  // User
  disableTour() {
    return makePostRequest('/user/first-visit')
  }

  getUser() {
    return makeGetRequest('/user')
  }

  changeEmail(emailForm: ChangeEmailForm) {
    return makePutRequest('/user/email', emailForm)
  }

  changePassword(passwordForm: ChangePasswordForm) {
    return makePutRequest('/user/password', passwordForm)
  }

  getQRCodeInfos() {
    return makeGetRequest('/user/2fa')
  }

  // 2FA
  enable2FA(code: string) {
    return makePostRequest(`/user/2fa/${code}`)
  }

  disable2FA(code: string) {
    return makeDeleteRequest(`/user/2fa/${code}`)
  }

  // Discord
  connectToDiscord(discordForm: DiscordDataForm) {
    return makePostRequest('/discord/oauth2', discordForm)
  }

  disconnectToDiscord() {
    return makeDeleteRequest('/discord/disconnect')
  }

  refreshDiscordInfos(): Promise<DiscordAccount> {
    return makeGetRequest('/discord?forceRefresh=true')
  }

  // Launcher
  getLauncherInfos() {
    return makeGetRequest('/launcher/dashboard/versions/latest')
  }

  getAccountSessions() {
    return makeGetRequest('/user/sessions')
  }
}

const Account = new AccountAPI()

export default Account
