const ItemRarities: ItemRarity[] = [
  { value: -2, name: '', color: '' },
  { value: -1, name: '', color: '' },
  { value: 0, name: '', color: '#FFF' },
  { value: 1, name: 'Useful ', color: '#C0BDFC' },
  { value: 2, name: 'Good ', color: '#72FF85' },
  { value: 3, name: 'High quality ', color: '#91CDff' },
  { value: 4, name: 'Excellent ', color: '#0EF902' },
  { value: 5, name: 'Ancient ', color: '#F8E2B3' },
  { value: 6, name: 'Mysterious ', color: '#FEDD02' },
  { value: 7, name: 'Legendary ', color: '#B2F304' },
  { value: 8, name: 'Phenomenal ', color: '#FF5E00' }
]

const ItemShellLevels = [
  /* Weapons */
  { id: 1, name: 'C-', color: '#FFCC00' },
  { id: 2, name: 'B-', color: '#FFFF00' },
  { id: 3, name: 'A-', color: '#99FF66' },
  { id: 4, name: 'S-', color: '#CCFF99' },
  { id: 5, name: 'C-', color: '#CC9AFF' },
  { id: 6, name: 'B-', color: '#CC9AFF' },
  { id: 7, name: 'A-', color: '#CC9AFF' },
  { id: 8, name: 'S-', color: '#CC9AFF' },
  { id: 9, name: 'C-', color: '#FF7AB2' },
  { id: 10, name: 'B-', color: '#FF7AB2' },
  { id: 11, name: 'A-', color: '#FF7AB2' },
  { id: 12, name: 'S-', color: '#FF7AB2' },
  /* Armors */
  { id: 13, name: 'C-', color: '#FFCC00' },
  { id: 14, name: 'B-', color: '#FFFF00' },
  { id: 15, name: 'A-', color: '#99FF66' },
  { id: 16, name: 'S-', color: '#CCFF99' },
  { id: 17, name: 'C-', color: '#CC9AFF' },
  { id: 18, name: 'B-', color: '#CC9AFF' },
  { id: 19, name: 'A-', color: '#CC9AFF' },
  { id: 20, name: 'S-', color: '#CC9AFF' },
  { id: 21, name: 'C-', color: '#FF7AB2' },
  { id: 22, name: 'B-', color: '#FF7AB2' },
  { id: 23, name: 'A-', color: '#FF7AB2' },
  { id: 24, name: 'S-', color: '#FF7AB2' },
]

const ItemShellWeaponOptions: ItemConfig[] = [
  { id: 1, name: 'DamageImproved', description: 'Enhanced Attack', flat: true },
  { id: 2, name: 'PercentageTotalDamage', description: 'Increased Overall Attack' },
  { id: 3, name: 'FatalBleeding', description: 'Fatal Bleeding' },
  { id: 4, name: 'Bleeding', description: 'Bleeding' },
  { id: 5, name: 'HeavyBleeding', description: 'Heavy Bleeding' },
  { id: 6, name: 'Blackout', description: 'Blackout' },
  { id: 8, name: 'DeadlyBlackout', description: 'Deadly Blackout' },
  { id: 11, name: 'DamageIncreasedEnemy', description: 'Increase Damage to Monster Races' },
  { id: 13, name: 'DamageIncreasedSmallMonster', description: 'Increase Damage to Low-Level Races' },
  { id: 14, name: 'DamageIncreasedBigMonster', description: 'Increase Damage to High-Level Races' },
  { id: 15, name: 'CriticalChance', description: '(Except sticks) Increased Chance of Critical Hits' },
  { id: 16, name: 'CriticalDamage', description: '(Except sticks) Increased Critical Damage' },
  { id: 17, name: 'AntiMagicDisorder', description: '(Sticks only) Undisturbed When Casting Spells', noValue: true },
  { id: 18, name: 'IncreasedFireProperties', description: 'Increase Fire Element', flat: true },
  { id: 19, name: 'IncreasedWaterProperties', description: 'Increase Water Element', flat: true },
  { id: 20, name: 'IncreasedLightProperties', description: 'Increase Light Element', flat: true },
  { id: 21, name: 'IncreasedDarkProperties', description: 'Increase Shadow Element', flat: true },
  { id: 22, name: 'IncreasedElementalProperties', description: 'Increase All Elements', flat: true },
  { id: 23, name: 'ReducedMPConsume', description: 'Reduce MP Consumption' },
  { id: 24, name: 'HPRecoveryForKilling', description: 'HP Recovery per Kill', flat: true },
  { id: 25, name: 'MPRecoveryForKilling', description: 'MP Recovery per Kill', flat: true },
  { id: 26, name: 'SLDamage', description: 'Increased SL Attack Stat', flat: true },
  { id: 27, name: 'SLDefence', description: 'Increased SL Defense Stat', flat: true },
  { id: 28, name: 'SLElement', description: 'Increased SL Element Stat', flat: true },
  { id: 29, name: 'SLHP', description: 'Increased SL Energy Stat', flat: true },
  { id: 30, name: 'SLGlobal', description: 'Increased Overall SL Stat', flat: true },
  { id: 31, name: 'GainMoreGold', description: '(Main weapon only) Gain More Gold' },
  { id: 32, name: 'GainMoreXP', description: '(Main weapon only) Gain More Job EXP' },
  { id: 33, name: 'GainMoreCXP', description: '(Main weapon only) Gain More Combat EXP' },
  { id: 34, name: 'PercentageDamageInPVP', description: 'Increase Damage in PvP' },
  { id: 35, name: 'ReducesPercentageEnemyDefenceInPVP', description: 'Reduce Opponent\'s Defences in PvP' },
  { id: 36, name: 'ReducesEnemyFireResistanceInPVP', description: 'Opponent\'s Fire Resistance Reduced in PvP' },
  { id: 37, name: 'ReducesEnemyWaterResistanceInPVP', description: 'Opponent\'s Water Resistance Reduced in PvP' },
  { id: 38, name: 'ReducesEnemyLightResistanceInPVP', description: 'Opponent\'s Light Resistance Reduced in PvP' },
  { id: 39, name: 'ReducesEnemyDarkResistanceInPVP', description: 'Opponent\'s Shadow Resistance Reduced in PvP' },
  { id: 40, name: 'ReducesEnemyAllResistancesInPVP', description: 'Opponent\'s All Resistances Reduced in PvP' },
]

const ItemShellArmorOptions: ItemConfig[] = [
  { id: 51, name: 'MeleeDefence', description: 'Enhanced Melee Defence', flat: true },
  { id: 52, name: 'DistanceDefence', description: 'Enhanced Ranged Defence', flat: true },
  { id: 53, name: 'MagicDefence', description: 'Enhanced Magic Defense', flat: true },
  { id: 54, name: 'PercentageTotalDefence', description: 'Increased Overall Defense' },
  { id: 57, name: 'ReducedAllBleedingType', description: 'Chance of All Bleedings Reduced' },
  { id: 59, name: 'ReducedAllStun', description: 'Chance of all Blackouts Reduced' },
  { id: 61, name: 'ReducedFreeze', description: 'Chance of Freeze Reduced' },
  { id: 64, name: 'ReducedArmorDeBuff', description: 'Chance of Weaken Defence Power Reduced' },
  { id: 65, name: 'ReducedShock', description: 'Chance of Shock reduced' },
  { id: 67, name: 'ReducedAllNegativeEffect', description: 'Chance of All Negative Effects Reduced' },
  { id: 68, name: 'RecoveryHPOnRest', description: 'HP Recovery While Resting' },
  { id: 69, name: 'RecoveryHP', description: 'Natural HP recovery', flat: true },
  { id: 70, name: 'RecoveryMPOnRest', description: 'MP Recovery While Resting' },
  { id: 71, name: 'RecoveryMP', description: 'Natural MP Recovery', flat: true },
  { id: 72, name: 'MaxHP', description: 'Increased Max HP', flat: true },
  { id: 73, name: 'ReducedCritChanceReceive', description: 'Chance of Receiving a Critical Hit Reduced' },
  { id: 74, name: 'IncreasedFireResistance', description: 'Increased Fire Resistance' },
  { id: 75, name: 'IncreasedWaterResistance', description: 'Increased Water Resistance' },
  { id: 76, name: 'IncreasedLightResistance', description: 'Increased Light Resistance' },
  { id: 77, name: 'IncreasedDarkResistance', description: 'Increased Shadow Resistance' },
  { id: 78, name: 'IncreasedAllResistance', description: 'Increased All Resistance' },
  { id: 79, name: 'ReducedPrideLoss', description: 'Reduced Dignity Loss' },
  { id: 80, name: 'ChanceNotConsume', description: 'Chance to Not Consume Foods and Potions' },
  { id: 81, name: 'IncreasedReputGain', description: 'Gain More Fame' },
  { id: 82, name: 'IncreasedRecoveryItemSpeed', description: 'Increased Recovery from Items' },
  { id: 83, name: 'PercentageAllPVPDefence', description: 'Increased Overall Defense in PvP' },
  { id: 84, name: 'CloseDefenceDodgeInPVP', description: 'Dodge Melee Attacks in PvP' },
  { id: 85, name: 'DistanceDefenceDodgeInPVP', description: 'Dodge Long Range Attacks in PvP' },
  { id: 86, name: 'IgnoreMagicDamage', description: 'Ignore Magic Damage in PvP' },
  { id: 87, name: 'DodgeAllDamage', description: 'Dodge All Attacks in PvP' },
  { id: 88, name: 'ProtectMPInPVP', description: 'Protect Mana in PvP', noValue: true }
]

const ItemShellOptions: ItemConfig[] = [
  ...ItemShellWeaponOptions,
  ...ItemShellArmorOptions,
]

const ItemRuneWeaponOptions = [
  { id: 3, weight: 0, name: 'IncreaseAllAttacks', description: 'All attacks are increased by', flat: true },
  { id: 4, weight: 0, name: 'IncreaseAllHitRates', description: 'Hit rate of all attacks is increased by', flat: true },
  { id: 4, weight: 3, name: 'IncreaseConcentrationFlat', description: '(Only Wand) Concentration increased by', flat: true },
  { id: 5, weight: 1, name: 'IncreaseCriticalHitsDamage', description: 'Critical damage dealt is increased by' },
  { id: 71, weight: 4, name: 'IncreasePVPDamage', description: 'Damage dealt in PvP is increased by' },
  { id: 96, weight: 0, name: 'IncreaseFairyDamage', description: 'The equipped fairy\'s element attribute level increases by' },
  { id: 96, weight: 2, name: 'IncreasePVEDamage', description: 'Damage dealt in PvE is increased by' },
  { id: 102, weight: 0, name: 'IncreaseSPAttackPoints', description: 'Your specialist\'s attack skill points are increased by', flat: true },
  { id: 102, weight: 2, name: 'IncreaseSPElementalPoints', description: 'Your specialist\'s elemental skill points are increased by', flat: true },
  { id: 102, weight: 4, name: 'IncreaseAllHitRates', description: '(Except Wand) All hit rates are increased by' },
  { id: 103, weight: 0, name: 'IncreaseNonElementalDamage', description: 'All attacks is increased by' },
  { id: 103, weight: 4, name: 'IncreaseConcentration', description: '(Only Wand) Concentration is increased by', flat: true },
  { id: 104, weight: 3, name: 'IncreaseNosMateAttackPower', description: 'Increases the attack power of your NosMate by' },
  { id: 200, weight: 0, name: 'MPUsage', description: 'All MP usages are decreased by' },
  { id: 218, weight: 1, name: 'IncreaseElementalDamage', description: 'Elemental damage dealt is increased by' },
]

const ItemRuneArmorOptions = [
  { id: 13, weight: 0, name: 'IncreaseAllResistances', description: 'All elemental resistances are increased by', flat: true },
  { id: 9, weight: 0, name: 'IncreaseAllDefencesFlat', description: 'All Defence Powers are increased by', flat: true },
  { id: 10, weight: 3, name: 'IncreaseAllDefences', description: 'Defence is increased by' },
  { id: 102, weight: 1, name: 'IncreaseSPDefencePoints', description: 'Your specialist’s defence skill points are increased by', flat: true },
  { id: 33, weight: 2, name: 'IncreaseMaxHP', description: 'Increases maximum HP by' },
  { id: 102, weight: 3, name: 'IncreaseSPHpMpPoints', description: 'Your specialist’s HP/MP skill points are increased by', flat: true },
  { id: 33, weight: 3, name: 'IncreaseMaxMP', description: 'Increases maximum MP by' },
  { id: 10, weight: 0, name: 'IncreaseDodgeFlat', decription: 'Dodge is increased by', flat: true },
  { id: 101, weight: 4, name: 'IncreaseDodge', description: 'Dodge is increased by' },
  { id: 98, weight: 0, name: 'IncreaseElementDamage', description: 'With a 100% probability all elemental damage is reduced by' },
  { id: 5, weight: 4, name: 'DamageReceivedFromCriticalDecreased', description: 'Damage from critical hits is reduced by' },
  { id: 5, weight: 3, name: 'ReceivingDecreased', description: 'Probability to receive critical hits is decreased by' },
  { id: 45, weight: 0, name: 'NeverBadEffectChance', description: 'Up to level 4 there is a X% chance of never getting a bad effect.', replace: true },
]


const ItemRunesWeaponPowersOptions = [
  { id: 105, weight: 0, name: 'ApocalypsePowerOnAttack', description: 'Apocalypse Power' },
  { id: 105, weight: 1, name: 'ReflectionPowerOnAttack', description: 'Reflection Power' },
  { id: 105, weight: 2, name: 'WolfPowerOnAttack', description: 'Wolf Power' },
  { id: 105, weight: 3, name: 'EnemyPushOnAttack', description: 'Kickback Power' },
  { id: 105, weight: 4, name: 'ExplosionPowerOnMeleeAttack', description: 'Explosion Power' },
  { id: 106, weight: 0, name: 'AgilityPowerOnAttack', description: 'Agility Power' },
  { id: 106, weight: 1, name: 'LightningPowerOnAttack', description: 'Lightning Power' },
  { id: 106, weight: 2, name: 'CursePowerOnAttack', description: 'Curse Power' },
  { id: 106, weight: 3, name: 'BearPowerOnAttack', description: 'Bear Power' },
  { id: 106, weight: 4, name: 'FrostPowerOnAttack', description: 'Frost Power' },
]

const ItemRunesArmorPowersOptions = [
{ id: 116, weight: 0, name: 'CleansingPowerOnDefence', description: 'Power of Cleansing Armour' },
{ id: 116, weight: 1, name: 'RegenerationPowerOnDefence', description: 'Power of Regeneration' },
{ id: 116, weight: 2, name: 'FlamePowerOnDefence', description: 'Power of the Flame' },
{ id: 116, weight: 3, name: 'PurityPowerOnDefence', description: 'Power of Purity' },
{ id: 116, weight: 4, name: 'ResistancePowerOnDefence', description: 'Power of Resistance' },
{ id: 117, weight: 0, name: 'BloodPowerOnDefence', description: 'Power of Blood' },
{ id: 117, weight: 1, name: 'ConversionPowerOnDefence', description: 'Power of Conversion' },
{ id: 117, weight: 2, name: 'UnyieldingPowerOnDefence', description: 'Power of Unyielding' },
{ id: 117, weight: 3, name: 'InstinctPowerOnDefence', description: 'Power of Instinct' },
{ id: 117, weight: 4, name: 'HealingPowerOnDefence', description: 'Power of Healing' },
]

const ItemAccessoryOptions = [
  { id: 0, name: 'HP', description: 'HP max increase', flat: true },
  { id: 1, name: 'MP', description: 'MP max increase', flat: true },
  { id: 2, name: 'HP regen', description: 'HP recovery increase', flat: true },
  { id: 3, name: 'MP regen', description: 'MP recovery increase', flat: true },
  { id: 4, name: 'ReducedMpCost', description: 'Reduces MP consumption by' },
  { id: 5, name: 'ReducedCrit', description: 'Reduces critical damage by' }
] 


const ItemFairyOptions: ItemConfig[] = [
  { id: 1, name: 'IncreaseHp', description: 'Increases HP by', flat: true },
  { id: 2, name: 'IncreaseHp', description: 'Increases MP by', flat: true },
  { id: 3, name: 'IncreaseHitratePercent', description: 'Hit rate is increased by' },
  { id: 4, name: 'ReducesCriticalDamagePercent', description: 'Reduces critical damage by' },
  { id: 5, name: 'ReducesCriticalChancePercent', description: 'Reduces chance of suffering critical hits by' },
  { id: 6, name: 'IncreaseDefencePower', description: 'All defence powers are increased by', flat: true },
  { id: 7, name: 'IncreaseCriticalDamagePercent', description: 'Increases critical damage by' },
  // { id: 8, name: 'IncreaseExperiencePercent', description: 'Enhanced Attack', flat: true },
  { id: 9, name: 'DecreaseDebuffTriggerUpToLevel4', description: 'Chance not to receive debuffs up to level 4' },
  { id: 10, name: 'DecreasePvPDamageTakenPercent', description: 'Reduces damage taken in PvP by'},
  { id: 11, name: 'IncreaseDefencePowerPercent', description: 'All defence powers are increased by' },
  { id: 12, name: 'IncreaseElementResistance', description: 'All elemental resistances are increased by', flat: true },
  // { id: 13, name: 'IncreaseChampionXp', description: 'Enhanced Attack', flat: true },
  { id: 14, name: 'IncreaseFairyElement', description: 'Increases the equipped fairy\'s element by', flat: true },
  { id: 15, name: 'IncreaseAllAttacksPercent', description: 'All attacks are increased by' },
  { id: 16, name: 'IncreaseCriticalChancePercent', description: 'Increases critical hit chance by' },
  { id: 17, name: 'IncreaseAllAttacks', description: 'All attacks are increased by', flat: true },
  { id: 18, name: 'IncreaseGoldPercent', description: 'Increases Gold earned by' },
  { id: 19, name: 'DecreaseOpponentElementalResistancePvP', description: 'Reduces opponent\'s elemental resistances in PvP by', flat: true },
  { id: 20, name: 'IncreasePvPAttackPower', description: 'Increases damage dealt in PvP by' },
  { id: 21, name: 'IncreaseHpPercent', description: 'Increases HP by' },
]

const ItemFairyLevels = [
  { id: 3, name: 'C-', color: '#FFFF00' },
  { id: 5, name: 'B-', color: '#99FF66' },
  { id: 7, name: 'A-', color: '#CCFF99' },
  { id: 9, name: 'S-', color: '#CC9AFF' },
]


export {
  ItemAccessoryOptions,
  ItemRarities,
  ItemShellOptions,
  ItemRuneWeaponOptions,
  ItemRuneArmorOptions,
  ItemShellLevels,
  ItemRunesWeaponPowersOptions,
  ItemRunesArmorPowersOptions,
  ItemFairyOptions,
  ItemFairyLevels,
}