import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import axios from '@/config/axios'
import { Auth } from '@/api'
import router from '@/router'
import { LocalStorageManager } from '@/services/Managers'

@Module({
  namespaced: true
})
export default class AuthModule extends VuexModule {
  isLogged = false

  @Mutation
  updateIsLoggedState() {
    this.isLogged = !this.isLogged
  }

  @Action
  async login(loginInfosForm: LoginInfosForm) {
    try {
      const {data, message} = await Auth.login(loginInfosForm)

      if (!data) {
        return message
      }

      const {token, refreshToken} = data

      if (!token && !refreshToken) {
        return data
      }

      axios.defaults.headers['Authorization'] = `Bearer ${token}`
      LocalStorageManager.setItem('OGG_AccessToken', token)

      if (refreshToken) {
        LocalStorageManager.setItem('OGG_RefreshToken', refreshToken)
      }

      this.context.commit('updateIsLoggedState')

      return data
    } catch (e) {
      console.error('Login error:', e)
    }
  }

  @Action
  async logout(isExpired: boolean = false) {
    delete axios.defaults.headers['Authorization'];

    try {
      await Auth.logout({
        token: LocalStorageManager.getItem('OGG_AccessToken')!
      })
    } catch (e) {
      console.error('Cannot logout', e)
    }

    const keys = [
      'OGG_AccessToken',
      'OGG_RefreshToken',
      'OGG_RefreshTokenExpiryDate'
    ];
    for (const e of keys) {
      LocalStorageManager.removeItem(e)
    }

    this.context.commit('updateIsLoggedState')
    const query = isExpired ? {sessionExpired: 'true'} : {}

    router.replace({
      path: '/login',
      query
    })
  }
}
