import {
  makeGetRequest,
  makePostRequest
} from './makeRequest'

class EventsAPI {
  getAdventCalendarQuestionOfTheDay() {
    return makeGetRequest('/advent-calendar/today')
  }

  answerAdventCalendarQuestionOfTheDay(adventCalendarQuestionForm: AdventCalendarQuestionForm) {
    return makePostRequest('/advent-calendar/today', adventCalendarQuestionForm)
  }

  getAnsweredAdventCalendarQuestions() {
    return makeGetRequest('/advent-calendar/answered')
  }

  getAnsweredAdventCalendarQuestionByDay(day: number) {
    return makeGetRequest(`/advent-calendar/${day}`)
  }
}

const eventsAPI = new EventsAPI()

export default eventsAPI