const ShopRoutes = [
  {
    path: '/purchase',
    name: 'purchase',
    component: () => import('@/views/Shop/Purchase.vue'),
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('@/views/Shop/Shop.vue')
  },
  {
    path: '/fortune-wheel',
    name: 'fortune-wheel',
    component: () => import('@/views/Shop/FortuneWheel.vue'),
    meta: {
      pageTitle: 'Fortune Wheel',
      breadcrumb: [
        { text: 'Shop', active: false },
        { text: 'Fortune Wheel', active: false },
      ]
    }
  },
]

export default ShopRoutes