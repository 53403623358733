import { makePostRequest } from './makeRequest'

class AuthAPI {
  login(loginInfosForm: LoginInfosForm) {
    return makePostRequest('/login/v2', loginInfosForm)
  }

  logout(logoutInfosForm: LogoutInfosForm) {
    return makePostRequest('/logout', logoutInfosForm)
  }

  register(registerInfosForm: RegisterInfosForm) {
    return makePostRequest('/register', registerInfosForm)
  }

  askNewPassword(askNewPasswordForm: NewPasswordForm) {
    return makePostRequest('/forgotten-password/request', askNewPasswordForm)
  }

  resetPassword(resetPasswordForm: ResetPasswordForm) {
    return makePostRequest('/forgotten-password/validate', resetPasswordForm)
  }

  validateAccount = (token: string) => {
    return makePostRequest(`/validate/${token}`)
  }

  disconnectSession = (sessionId: string) => {
    return makePostRequest(`/user/disconnect/${sessionId}`)
  }
}

const Auth = new AuthAPI()

export default Auth
