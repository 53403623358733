import { pluralize } from '@/services/functions'
import { DATEISOSTRING_REGEX, TIME_REGEX } from '@/services/Validation/regexes'

class DateManager {
  isISODateString(date) {
    return DATEISOSTRING_REGEX.test(date)
  }

  isTimeDurationString(date) {
    return TIME_REGEX.test(date)
  }

  parseTimeDelta(start: Date, end: Date = new Date()) {
    const timestamp = Math.abs(end.getTime() - start.getTime())
    const date = new Date(timestamp)
    const days = date.getUTCDate() - 1
    const hours = date.getUTCHours()
    const minutes = date.getMinutes()

    if (minutes < 1) {
      return 'Less than a minute'
    }

    return `${days > 0 ? `${pluralize('day', days)}` : ''} ${days < 1 && hours > 0 ? `${pluralize('hour', hours)}` : ''} ${hours < 1 && minutes > 0 ? `${pluralize('minute', minutes)}` : ''}`.trim()
  }

  compareDatesFromNow(date: Date | string) {
    const currentDate = new Date().getTime()
    const toCompareDate = new Date(date).getTime()

    return currentDate > toCompareDate
  }

  getRemainingTime(date: Date | string) {
    const date1 = new Date()
    const date2 = new Date(date)
    const delta = Math.abs(date2.getTime() - date1.getTime()) / 1000

    const minutes = Math.floor(delta / 60)
    let hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)

    hours -= (days * 24)

    return {
      days,
      hours
    }
  }

  getDateOnlyFromDateString(date: string) {
    return date.split(' ').slice(0, 4).join(' ')
  }
}

const dateManager = new DateManager()

export default dateManager