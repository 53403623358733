const defaultGravatarAvatarURL = 'https://www.gravatar.com/avatar/be9816d1b5d858234cd0548942906d8b.png'
const defaultBrowserAvatarURL = 'https://cdn-icons.flaticon.com/png/512/3542/premium/3542902.png?token=exp=1650748393~hmac=a7e75ad78b894f593676c90e15f39930'
const defaultItemIconURL = 'https://cdn.olympusgg.com/images/0.png'

class ImageResolver {
  setDefaultAvatar(e) {
    e.target.src = defaultGravatarAvatarURL
  }

  setDefaultItemIcon(e) {
    e.target.src = defaultItemIconURL
  }

  setDefaultBrowserIcon(e) {
    e.target.src = defaultBrowserAvatarURL
  }

  getIcon(id) {
    return `https://cdn.olympusgg.com/images/${id}.png`
  }
}

const imageResolver = new ImageResolver()

export default imageResolver