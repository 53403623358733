import store from '@/store'
import { NavigationGuardNext, Route } from 'vue-router'

const isUserCharacter = async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  const { characters } = store.state.game
  const hasAccess = characters
    .map(e => e.id)
    .includes(parseInt(to.params.characterId))

  if (hasAccess) {
    next()
  } else {
    next('/')
  }
}

export default isUserCharacter