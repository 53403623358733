import { URLManager } from '@/services/Managers'
import { makeGetRequest, makePostRequest } from './makeRequest'

class LogsAPI {
  getCouponLogs() {
    return makeGetRequest('/logs/coupon')
  }

  getDonationLogs() {
    return makeGetRequest('/logs/donations')
  }

  getConnectionLogs() {
    return makeGetRequest('/logs/login')
  }

  getShopLogs() {
    return makeGetRequest('/logs/shop')
  }

  getCharacterLogsTypes(): Promise<any> {
    return makeGetRequest('/gm/characters/logs/collections')
  }

  getCharacterLogsBasedOnLogType(logTypeForm: LogTypeForm) {
    return makePostRequest('/gm/characters/logs', logTypeForm)
  }
  
  getBidLogs(bidLogId: string) {
    return makeGetRequest(`/game/bid/gm/${bidLogId}`)
  }

  getPunishmentLogs(searchPunishmentLogForm: SearchPunishmentLogForm, searchPunishmentLogQueries: SearchPunishmentLogQueries) {
    const queriesParams = URLManager.buildParams(searchPunishmentLogQueries)

    return makePostRequest(`/gm/punishments/logs?${queriesParams}`, searchPunishmentLogForm)
  }

  getUserConnectionLogsById(id: string) {
    return makeGetRequest(`/admin/user/${id}/logs/login`)
  }

  getUserDonationLogsById(id: string) {
    return makeGetRequest(`/admin/user/${id}/logs/donation`)
  }
  
  getUserShopLogsById(id: string) {
    return makeGetRequest(`/admin/user/${id}/logs/shop`)
  }

  getUserCouponLogsById(id: string) {
    return makeGetRequest(`/admin/user/${id}/logs/coupon`)
  }

  getUserPunishmentsLogsById(id: string) {
    return makeGetRequest(`/admin/user/${id}/logs/bans`)
  }

  getCharacterTransfersLogs() {
    return makeGetRequest('/admin/users/transferred')
  }

  getFortuneWheelLogs() {
    return makeGetRequest('/fortune-wheel/logs')
  }

  getNicknameChanges() {
    return makeGetRequest('/player/name-history')
  }

  getNicknameChangesById(characterId: string | number) {
    return makeGetRequest(`/player/name-history/${characterId}`)
  }
}

const Logs = new LogsAPI()

export default Logs