export const getParsedSPAttackPoints = (spPoint) => {
  switch (true) {
    case (spPoint <= 10): return spPoint
    case (spPoint <= 28): return 10 + (spPoint - 10) / 2
    case (spPoint <= 88): return 19 + (spPoint - 28) / 3
    case (spPoint <= 168): return 39 + (spPoint - 88) / 4
    case (spPoint <= 268): return 59 + (spPoint - 168) / 5
    case (spPoint <= 334): return 79 + (spPoint - 268) / 6
    case (spPoint <= 383): return 90 + (spPoint - 334) / 7
    case (spPoint <= 391): return 97 + (spPoint - 383) / 8
    case (spPoint <= 400): return 98 + (spPoint - 391) / 9
    case (spPoint <= 410): return 99 + (spPoint - 400) / 10
    case (spPoint <= 425): return 100 + (spPoint - 410) / 3
    case (spPoint <= 481): return 105 + (spPoint - 425) / 4
    case (spPoint <= 486): return 119 + (spPoint - 481) / 5
  }
}

export const getParsedSPDefencePoints = (spPoint) => {
  switch (true) {
    case (spPoint <= 10): return spPoint
    case (spPoint <= 48): return 10 + (spPoint - 10) / 2
    case (spPoint <= 81): return 29 + (spPoint - 48) / 3
    case (spPoint <= 161): return 40 + (spPoint - 81) / 4
    case (spPoint <= 236): return 60 + (spPoint - 161) / 5
    case (spPoint <= 290): return 75 + (spPoint - 236) / 6
    case (spPoint <= 360): return 84 + (spPoint - 290) / 7
    case (spPoint <= 400): return 97 + (spPoint - 360) / 8
    case (spPoint <= 410): return 99 + (spPoint - 400) / 10
    case (spPoint <= 425): return 100 + (spPoint - 410) / 3
    case (spPoint <= 481): return 105 + (spPoint - 425) / 4
    case (spPoint <= 486): return 119 + (spPoint - 481) / 5
  }
}


export const getParsedSPElementPoints = (spPoint) => {
  switch (true) {
    case (spPoint <= 20): return spPoint
    case (spPoint <= 40): return 20 + (spPoint - 20) / 2
    case (spPoint <= 70): return 30 + (spPoint - 40) / 3
    case (spPoint <= 110): return 40 + (spPoint - 70) / 4
    case (spPoint <= 210): return 50 + (spPoint - 110) / 5
    case (spPoint <= 270): return 70 + (spPoint - 210) / 6
    case (spPoint <= 410): return 80 + (spPoint - 270) / 7
    case (spPoint <= 425): return 100 + (spPoint - 410) / 3
    case (spPoint <= 481): return 105 + (spPoint - 425) / 4
    case (spPoint <= 486): return 119 + (spPoint - 481) / 5
  }
}

export const getParsedSPEnergyPoints = (spPoint) => {
  switch (true) {
    case (spPoint <= 10): return spPoint
    case (spPoint <= 50): return 10 + (spPoint - 10) / 2
    case (spPoint <= 110): return 30 + (spPoint - 50) / 3
    case (spPoint <= 150): return 50 + (spPoint - 110) / 4
    case (spPoint <= 200): return 60 + (spPoint - 150) / 5
    case (spPoint <= 260): return 70 + (spPoint - 200) / 6
    case (spPoint <= 330): return 80 + (spPoint - 260) / 7
    case (spPoint <= 410): return 90 + (spPoint - 330) / 8
    case (spPoint <= 425): return 100 + (spPoint - 410) / 3
    case (spPoint <= 481): return 105 + (spPoint - 425) / 4
    case (spPoint <= 486): return 119 + (spPoint - 481) / 5
  }
}