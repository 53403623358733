const UserRoutes = [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/User/Profile.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/User/Settings.vue'),
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [
        { text: 'Profile', active: false },
        { text: 'Settings', active: false },
      ]
    }
  },
  {
    path: '/logs',
    name: 'logs',
    component: () => import('@/views/User/Logs.vue'),
    meta: {
      pageTitle: 'Logs',
      breadcrumb: [
        { text: 'Profile', active: false },
        { text: 'Logs', active: false },
      ]
    }
  },
  {
    path: '/coupons',
    name: 'coupons',
    component: () => import('@/views/User/Coupons.vue'),
    meta: {
      pageTitle: 'Coupons',
      breadcrumb: [
        { text: 'Profile', active: false },
        { text: 'Coupons', active: false },
      ]
    }
  }
]

export default UserRoutes