import { checkIfGM } from '../middlewares/checkAuthority'

const GMRoutes = [
  {
    path: '/gm',
    name: 'gm',
    component: () => import('@/views/GM/GMPanel.vue'),
    meta: {
      pageTitle: 'GM Panel',
      breadcrumb: [
        { text: 'GM Panel', active: false }
      ]
    }
  },
  {
    path: '/gm/characters/view/:characterId',
    name: 'gm-character-id',
    component: () => import('@/views/Character.vue'),
  },
  {
    path: '/gm/shell-parser',
    name: 'shell-parser',
    component: () => import('@/views/GM/ShellParser.vue'),
    meta: {
      pageTitle: 'Shell Parser',
      breadcrumb: [
        { text: 'GM Panel', active: false },
        { text: 'Shell Parser', active: true }
      ]
    }
  },
  {
    path: '/gm/bulk-user-ban',
    name: 'bulk-user-ban',
    component: () => import('@/views/GM/BulkUserBan.vue'),
    meta: {
      pageTitle: 'Bulk User Ban',
      breadcrumb: [
        { text: 'GM Panel', active: false },
        { text: 'Bulk User Ban', active: true }
      ]
    }
  },
  {
    path: '/gm/logs/character',
    name: 'character-logs', 
    component: () => import('@/views/GM/CharacterLogs.vue'),
    meta: {
      pageTitle: 'Character Logs',
      breadcrumb: [
        { text: 'GM Panel', active: false },
        { text: 'Character Logs', active: false }
      ]
    }
  },
  {
    path: '/gm/logs/bids',
    name: 'bids-information', 
    component: () => import('@/views/GM/BIdsInformation.vue'),
    meta: {
      pageTitle: 'Bids Information',
      breadcrumb: [
        { text: 'GM Panel', active: false },
        { text: 'Bids Logs', active: false }
      ]
    }
  },
  {
    path: '/gm/find-alts',
    name: 'find-alts',
    component: () => import('@/views/GM/FindAlts.vue'),
    meta: {
      pageTitle: 'Find Alts',
      breadcrumb: [
        { text: 'GM Panel', active: false },
        { text: 'Find Alts', active: false }
      ]
    }
  },
  {
    path: '/gm/nickname-changes',
    name: 'nickname-changes',
    component: () => import('@/views/GM/NicknameChanges.vue'),
    meta: {
      pageTitle: 'Nickname Changes',
      breadcrumb: [
        { text: 'GM Panel', active: false },
        { text: 'Nickname Changes', active: false }
      ]
    }
  },
  {
    path: '/gm/logs/punishments',
    name: 'punishment-logs',
    component: () => import('@/views/GM/PunishmentLogs.vue'),
    meta: {
      pageTitle: 'Punishment Logs',
      breadcrumb: [
        { text: 'GM Panel', active: false },
        { text: 'Punishment Logs', active: false }
      ]
    }
  },
]

GMRoutes.forEach((route: any) => {
  route.beforeEnter = checkIfGM
})

export default GMRoutes