import AppRoutes from './AppRoutes'
import AdminRoutes from './AdminRoutes'
import AuthRoutes from './AuthRoutes'
import ErrorRoutes from './ErrorRoutes'
import GameRoutes from './GameRoutes'
import GMRoutes from './GMRoutes'
import SGMRoutes from './SGMRoutes'
import ShopRoutes from './ShopRoutes'
import UserRoutes from './UserRoutes'
import RootRoutes from './RootRoutes'

export default [
  ...AppRoutes,
  ...AdminRoutes,
  ...AuthRoutes,
  ...ErrorRoutes,
  ...GameRoutes,
  ...GMRoutes,
  ...SGMRoutes,
  ...RootRoutes,
  ...ShopRoutes,
  ...UserRoutes
]