import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { CharacterFactory } from '@/services/Factories'
import { Admin, GM, Game } from '@/api'

@Module({
  namespaced: true
})
export default class GameModule extends VuexModule {
  characters: Character[] = []
  userCharacters: Character[] = []
  gameAccounts: GameAccount[] = []
  rankingTypes: RankingType[] = []
  viewCharacter: Nullable<Character> = null

  @Mutation
  setCharacters(characters: Character[]) {
    this.characters = characters
  }

  @Mutation
  updateCharacterActive(characterId: any) {
    const character = this.characters.find(e => e.id === characterId)!

    character.isActive = !character?.isActive
  }

  @Mutation
  setUserCharacters(characters: Character[]) {
    this.userCharacters = characters
  }

  @Mutation
  setGameAccounts(gameAccounts: GameAccount[]) {
    this.gameAccounts = gameAccounts
  }

  @Mutation
  setRankingTypes(rankingTypes: RankingType[]) {
    this.rankingTypes = rankingTypes
  }

  @Mutation
  setViewCharacter(character: Character) {
    this.viewCharacter = character
  }

  @Action({ rawError: true })
  async getCharacters(buildCharacterForm: BuildCharacterForm) {
    const { data, APIType, commit } = buildCharacterForm
    let rawCharacters = []

    switch (APIType) {
      case 'Admin':
        rawCharacters = await Admin.getUserCharacters(data)
        break;
      case 'GM':
        rawCharacters = (await GM.getCharactersByIds(data)).map(e => e.character)
        break;
      default:
        rawCharacters = await Game.getCharacters()
        break;
    }

    // Filter Boolean to remove characters that cannot be fetched for some reason
    const characters = rawCharacters
      .filter(Boolean)
      .map(character => new CharacterFactory(character).buildCharacter())
    let charactersResults = await Promise.all<Character>(characters)

    if (commit) {
      this.context.commit('setCharacters', charactersResults)
    }

    return charactersResults
  }

  @Action({ commit: 'setGameAccounts' })
  async getGameAccounts(): Promise<GameAccount[]> {
    const gameAccounts: GameAccount[] = await Game.getGameAccounts()

    return gameAccounts
  }

  @Action({ commit :'setRankingTypes' })
  async getRankingTypes(): Promise<RankingType[]> {
    const rankingTypes: string[] = await Game.getRankingTypes()
    const units = {
      'monster': 'monsters killed',
      'rbb': 'wins',
      'icebreaker': 'wins',
      'raid': 'raids completed',
      'achievements': 'achievements points',
      'act4': 'aact4 score',
      'aot': 'wins'
    }

    return rankingTypes.map((rt: string) => ({
      name: rt.slice(4),
      description: units[rt.slice(4).toLowerCase()],
      type: rt
    })) as RankingType[]
  }
}