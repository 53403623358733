import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { Shop } from '@/api'

@Module({
  namespaced: true
})
export default class ShopModule extends VuexModule {
  // Shop
  initialShopItems: Pack[] = []
  shopItems: Pack[] = []
  shopCategories = []

  // Shop filters
  searchItemName: string = ''
  searchCategoryIds: number[] = []
  searchPriceMax: number = 0
  searchSortBy: string = ''

  get highestItemPrice() {
    // Get Max Price of an item rounded to the next multiple of 500 to match price filter
    return Math.ceil((Math.max(...this.initialShopItems.map(e => e.price), 0)) / 500) * 500
  }

  get filteredShopItems() {
    return this.shopItems
      .filter(e => this.searchCategoryIds.length > 0 ? this.searchCategoryIds.includes(e.categoryId) : e)
      .filter(e => this.searchItemName ? e.name.toLowerCase().includes(this.searchItemName.toLowerCase()) : e)
      .filter(e => this.searchPriceMax ? e.price < this.searchPriceMax : e)
  }

  @Mutation
  setShopItems(items: Pack[]) {
    this.initialShopItems = JSON.parse(JSON.stringify(items))
    this.shopItems = items
  }

  @Mutation
  setShopCategories(categories) {
    this.shopCategories = categories
  }

  @Mutation
  setSearchItemName(name: string) {
    this.searchItemName = name.trim()
  }

  @Mutation
  setSearchCategoryIds(ids: number[]) {
    this.searchCategoryIds = ids
  }

  @Mutation
  setSearchPriceMax(price: number) {
    this.searchPriceMax = price
  }

  @Mutation
  setSortBy(sortBy: string) {
    let sortedPacks: Pack[] = []

    switch (sortBy) {
      case 'alphaUp':
        sortedPacks = this.shopItems.sort((a, b) => {
          return b.name > a.name ? -1 : a.name > b.name ? 1 : 0
        })
        break
      case 'alphaDown':
        sortedPacks = this.shopItems.sort((a, b) => {
          return a.name > b.name ? -1 : b.name > a.name ? 1 : 0
        })
        break
      case 'priceUp':
        sortedPacks = this.shopItems.sort((a, b) => a.price - b.price)
        break
      case 'priceDown':
        sortedPacks = this.shopItems.sort((a, b) => b.price - a.price)
        break
      case 'reset':
        sortedPacks = [...this.initialShopItems]
        break
    }

    this.shopItems = sortedPacks
  }

  @Action({ commit: 'setShopItems' })
  async getShopItems(): Promise<Pack[] | boolean> {
    return Shop.getShopItems()
  }

  @Action({ commit: 'setShopCategories' })
  async getShopCategories(): Promise<Pack[] | boolean> {
    return Shop.getCategories()
  }
}