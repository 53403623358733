import store from '@/store'
import { NavigationGuardNext, Route } from 'vue-router'

const checkIfLogged = async (next: NavigationGuardNext<Vue>) => {
  const isLogged = store.state.auth.isLogged

  if (isLogged) {
    next()
  } else {
    next({ name: 'login' })
  }
}
export default checkIfLogged