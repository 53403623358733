import {
  ItemAccessoryOptions,
  ItemFairyLevels,
  ItemFairyOptions,
  ItemRarities,
  ItemRuneArmorOptions,
  ItemRuneWeaponOptions,
  ItemRunesArmorPowersOptions,
  ItemRunesWeaponPowersOptions,
  ItemShellLevels,
  ItemShellOptions
} from './ItemConfig'
import { EquipementSlotType } from './ItemSlotType'
import {
  getParsedSPAttackPoints,
  getParsedSPDefencePoints,
  getParsedSPElementPoints,
  getParsedSPEnergyPoints
} from '@/services/Formulas/SPPointsConverter'

export class ItemFactory {
  item: any

  constructor(item: any) {
    this.item = item.itemInstance ? item.itemInstance : item
    this.setBaseitemConfig(item)
  }

  setBaseitemConfig(item) {
    this.item.slot = item.slot >= 0 ? item.slot : null
    this.item.inventoryType = item.inventoryType
    this.item.isEquipped = item.isEquipped
  }

  public buildItem({ name, iconId }) {
    const { amount, type, itemVNum, upgrade, equipmentOptions, serialTracker } = this.item

    return {
      ...this.item,
      amount,
      fullName: this.formatItemName(name, upgrade),
      name,
      iconId,
      imageURL: `https://cdn.olympusgg.com/images/${iconId}.png`,
      rarity: this.equipmentRarity,
      upgrade,
      type, // TODO: EquipmentType
      vnum: itemVNum,
      equipmentOptions,
      serialTracker,
    }
  }

  /* get isInventoryItem(): boolean {
    const { isEquipped, inventoryType, slot, itemInstance } = this.item

    return isEquipped && inventoryType && slot >= 0 && itemInstance
  } */

  get equipmentRarity(): ItemRarity {
    /* const equippedItemsHasRarities = [
      EquipementSlotType.MainWeapon,
      EquipementSlotType.SecondaryWeapon,
      EquipementSlotType.Armor,
    ]

    const setNoRarity = (this.item.inventoryType
      && this.item.inventoryType === 'EquippedItems'
      && !equippedItemsHasRarities.includes(this.item.slot))
      || (this.isResistance) */

    if (this.isResistance || this.item.slot === EquipementSlotType.Fairy) {
      return { value: 0, name: '', color: '#FFF' }
    }

    return ItemRarities.find(e => e.value === (this.item.rarity || 0))!
  }

  get isResistance(): boolean {
    const { fireResistance, waterResistance, lightResistance, darkResistance } = this.item

    return fireResistance || waterResistance || lightResistance || darkResistance
  }

  private formatItemName(name, upgrade): string {
    const rarity = this.equipmentRarity

    return `<span style="color:${rarity?.color}">${rarity?.name} ${name} ${this.formatUpgrade(upgrade)}</span>`
  }

  private formatUpgrade(upgrade): string {
    if (this.isResistance) {
      return `Sum${upgrade}`
    }

    return upgrade > 0 ? `+${upgrade}` : ''
  }
}

export const getItemShell = (item) => {
  const options = item?.equipmentOptions.map(e => {
    if (['RUNE', 'JEWELS'].includes(e.equipmentOptionType)) return

    const option: Nullable<ItemConfig> = e.equipmentOptionType === 'FAIRY_ENCHANT'
      ? ItemFairyOptions.find(item => item.id === e.type)
      : ItemShellOptions.find(item => item.id === e.type)
    const shellLevel = e.equipmentOptionType === 'FAIRY_ENCHANT'
      ? ItemFairyLevels.find(item => item.id === e.level)
      : ItemShellLevels.find(item => item.id === e.level)

    if (option) {
      return `
        <span style="color:${shellLevel?.color}">
          ${shellLevel?.name}${option?.description}${option?.noValue ? '' : `${e.equipmentOptionType === 'FAIRY_ENCHANT' ? '' : ':'} ${e.value}${option?.flat ? '' : '%'}`}
        </span>
      `
    }
  })

  return options.filter(Boolean).join('<br />')
}

export const getItemRunes = (item) => {
  const options = item?.equipmentOptions.map(e => {
    if (e.equipmentOptionType !== "RUNE") return

    const option: any = [
      ...ItemRuneWeaponOptions,
      ...ItemRuneArmorOptions
    ].find(item => item.id === e.type && item.weight === e.weight)

    if (option) {
      return `
        <span style="color:#FF862C">
          ${option?.description}${option?.noValue ? '' : ` ${e.value}${option?.flat ? '' : '%'}`}
        </span>
      `
    }
  })

  return options.filter(Boolean).join('<br />')
}

export const getItemRunePowers = (item) => {
  const options = item?.equipmentOptions.map(e => {
    if (e.equipmentOptionType !== "RUNE") return

    const option: any = [
      ...ItemRunesWeaponPowersOptions,
      ...ItemRunesArmorPowersOptions
    ].find(item => item.id === e.type && item.weight === e.weight)

    if (option) {
      return `
        <span>
          ${option?.description}${option?.noValue ? '' : ` (Level ${e.value})`}
        </span>
      `
    }
  })

  return options.filter(Boolean).join('<br />')
}

export const getItemJewels = (item) => {
  const options = item?.equipmentOptions.map(e => {
    if (!['JEWELS', 1].includes(e.equipmentOptionType)) return

    const option: any = ItemAccessoryOptions.find(item => item.id === e.type)

    if (option) {
      return `
        <span style="color: #F5C478">
          ${e.level}Lv ${option?.description}${` ${e.value}${option?.flat ? '' : '%'}`}
        </span>
      `
    }
  })

  return options.filter(Boolean).join('<br />')
}

export const getSPStats = (item) => {
  const {
    slDamage, slDefence, slElement, slHP,
    spDamage, spDefence, spElement, spHP,
    spFire, spWater, spLight, spDark
  } = item

  const attackStats = `${getParsedSPAttackPoints(slDamage || 0)} (+${spDamage || 0})`
  const defenseStats = `${getParsedSPDefencePoints(slDefence || 0)} (+${spDefence || 0})`
  const elementStats = `${getParsedSPElementPoints(slElement || 0)} (+${spElement || 0})`
  const energyStats = `${getParsedSPEnergyPoints(slHP || 0)} (+${spHP || 0})`

  const slStats = `
    <div class="d-flex justify-content-around">
      <span class="text-white"> Attack ${attackStats} </span>
      <span class="text-white"> Defense ${defenseStats} </span>
      <span class="text-white"> Element ${elementStats} </span>
      <span class="text-white"> Energy ${energyStats} </span>
    </div>
  `

  const resistanceStats = `
    <div class="d-flex justify-content-around">
      <span><span style="color: #FF862C"> Fire </span> ${spFire}</span>
      <span><span style="color: #0096FF"> Water </span> ${spWater}</span>
      <span><span style="color: #FFFF00"> Light </span> ${spLight}</span>
      <span><span style="color: #848884"> Dark </span> ${spDark}</span>
    </div>
  `

  return `
    ${slStats}
    <hr />
    ${resistanceStats}
  `
}

export const getResistances = (item) => {
  const { darkResistance, fireResistance, waterResistance, lightResistance } = item
  const isRes = fireResistance || waterResistance || lightResistance || darkResistance

  if (!isRes) return

  return `
    <span style="color: #F5C478"> FireElement Resistance: ${fireResistance}% </span> <br />
    <span style="color: #F5C478"> WaterElement Resistance: ${waterResistance}% </span> <br />
    <span style="color: #F5C478"> LightElement Resistance: ${lightResistance}% </span> <br />
    <span style="color: #F5C478"> DarkElement Resistance: ${darkResistance}% </span> <br />
  `
}

export const parseLogItems = (logItems) => {
  return getItemShell(logItems)
}

export const itemHoverConfig = (item, options?) => {
  const { vnum, name, rarity, type, upgrade, holdingVNum } = item

    return {
      html: true,
      title: `${name}${options.displayGMOptions ? ` (${vnum})`: ''}`,
      content: () => {
        let itemJewels: any,
            itemShells: any,
            itemRunes: any,
            itemRunePowers: any,
            itemResistances: any,
            spStats: any

        switch (type) {
          case 'SpecialistInstance':
          case 3:
            spStats = getSPStats(item)
            break;
          case 'WearableInstance':
          case 1:
            if (item.equipmentOptions) {
              itemJewels = getItemJewels(item)

              if (!itemJewels)
                itemShells = getItemShell(item)

              itemRunes = getItemRunes(item)
              itemRunePowers = getItemRunePowers(item)
            }
            itemResistances = getResistances(item)
            break;
        }

        return `
          ${options.fromShellParser ? `<span class="text-white" style="font-size: 24px">${name} (${vnum})</span><br />`: '' }
          ${options.displayGMOptions ? `<span class="text-white">Serial Tracker</span>: ${item.serialTracker} <br />` : ''}
          <span class="text-white">Slot</span>: ${item.slot?.name || item.slot || '-'} <br />
          <span class="text-white">Rarity</span>: ${rarity?.value || 0} <br />
          <span class="text-white">Upgrade</span>: ${upgrade} <br />
          ${['SpecialistInstance', 3].includes(item.type) ? `<span class="text-white">Perfection</span>: ${item.spStoneUpgrade} <br />` : '' }
          <span class="text-white">Type</span>: ${type} <br />
          ${['BoxInstance'].includes(item.type) ? `<span class="text-white">Holding Vnum</span>: ${item.holdingVNum} <br />` : '' }
          <div style="text-align: center">
            ${itemJewels ? `<hr />${itemJewels}` : '' }
            ${itemShells ? `<hr />${itemShells}` : '' }
            ${itemRunes ? `<hr />${itemRunes}` : '' }
            ${itemRunePowers ? `<hr />${itemRunePowers}` : '' }
            ${itemResistances ? `<hr />${itemResistances}` : '' }
            ${spStats ? `<hr />${spStats}` : '' }
          </div>
        `
      }
    }
}