import { camelToSnakeUpperCase } from '@/services/functions'
import { ItemFactory } from '@/services/Factories'
import { Game } from '@/api'
import slots from '../Item/ItemSlotType'
import { SPLIT_COMMAS_REGEX } from '@/services/Validation/regexes'

export default class CharacterFactory {
  rawCharacter: any
  generatedCharacter: any

  constructor(rawCharacter: any) {
    this.rawCharacter = rawCharacter
  }

  public async buildCharacter(): Promise<Character> {
    const {
      isActive,
      accountId, id, name,
      level, heroLevel, jobLevel,
      gold, slot, bankMoney,
      biography,
      act4Kill, act4Dead, act4Points,
      talentWin, talentLose, talentSurrender
    } = this.rawCharacter
  
    return {
      accountId,
      isActive,
      id,
      name,
      class: this.getClass(),
      level,
      heroLevel: heroLevel || 0,
      jobLevel,
      biography: biography?.replaceAll('\u000b', ' '),
      bonus: await this.getBonus(),
      bankMoney: bankMoney?.toString().replace(SPLIT_COMMAS_REGEX, ",") || 0,
      gold: gold?.toString().replace(SPLIT_COMMAS_REGEX, ",") || 0,
      slot,
      reputation: this.getReputation(),
      faction: this.getFaction(),
      inventory: {
        backpack: await this.getBackpack(),
        partnerBackpack: await this.getSubInventory('partnerWarehouse')
      },
      stuff: await this.getEquippedItems(),
      mates: null, // this.getMates(),
      titles: this.getTitles(),
      stats: this.getStats(),
      pvpStats: {
        act4: {
          act4Kill,
          act4Dead,
          act4Points
        },
        talentArena: {
          talentWin,
          talentLose,
          talentSurrender
        }
      }
    }
  }

  getClass(): CharacterClass {
    const characterClass = this.rawCharacter.class
    let sexes: Nullable<{ male: number, female: number }> = null

    switch (characterClass) {
      case 'Swordman':
        sexes = { male: 32040, female: 32060 }
        break
      case 'Archer':
        sexes = { male: 32080, female: 32100 }
        break
      case 'Magician':
        sexes = { male: 32120, female: 32140 }
        break
      case 'MartialArtist':
        sexes = { male: 32556, female: 32557 }
        break
      default:
        sexes = { male: 32000, female: 32020 }
    }

    return {
      name: characterClass,
      avatarId: this.rawCharacter.gender === 'Male' ? sexes.male : sexes.female
    }
  }

  private async getEquippedItems(): Promise<Item[]> {
    const equippedItemsIds = this.rawCharacter.equippedStuffs?.map(({ itemInstance }) => itemInstance.itemVNum) || []
    const names = await Game.getItemsNames(equippedItemsIds) || []
    const icons = await Game.getItemsIcons(equippedItemsIds) || []
    const itemData = names.map((item, i) => Object.assign({}, item, icons[i]))
    const equippedItems = this.rawCharacter.equippedStuffs?.map(item => {
      const { name, iconId } = itemData.find(e => e.vnum == item.itemInstance.itemVNum)
  
      return new ItemFactory(item).buildItem({ name, iconId })
    }) || []

    const findItemOnSlot = (slotId: number) => {
      return equippedItems.find(e => e.slot === slotId)
    }

    const emptySlot = {
      isEquipped: true,
      emptySlot: true
    }

    const modifiedEquippedItems = slots.map(({ id, name }) => {
      const slot = { id, name }
      const itemOnSlot = findItemOnSlot(id)

      if (itemOnSlot)
        itemOnSlot.slot = slot

      return itemOnSlot || {
        slot,
        ...emptySlot
      }
    })

    const slotOrder = [9, 2, 10, 0, 12, 5, 3, 1, 4, 7, 6, 8, 16, 100, 11, 15, 14, 13]
    const sortedEquippedItems = modifiedEquippedItems.sort((a, b) => {
      return slotOrder.indexOf(a.slot.id) - slotOrder.indexOf(b.slot.id)
    })

    return sortedEquippedItems
  }

  // What a trash piece of code what the fuck ??
  private async getBackpack(): Promise<CharacterBackpack> {
    const inventoryItemsIds = this.rawCharacter.inventory.map(({ itemInstance }) => itemInstance.itemVNum)
    const names = await Game.getItemsNames(inventoryItemsIds)
    const icons = await Game.getItemsIcons(inventoryItemsIds)
    const itemData = names.map((item, i) => Object.assign({}, item, icons[i]))

    const inventory = this.rawCharacter.inventory.map(item => {
      const { name, iconId } = itemData.find(e => e.vnum == item.itemInstance.itemVNum)
  
      return new ItemFactory(item).buildItem({ name, iconId })
    })

    const filterByInventoryType = (inventoryType: string) => {
      return inventory
        .filter(e => e.inventoryType === inventoryType)
        .sort((a: any, b: any) => a.slot - b.slot)
    }

    const costume = filterByInventoryType('Costume')
    const equipment = filterByInventoryType('Equipment')
    const etc = filterByInventoryType('Etc')
    const main = filterByInventoryType('Main')
    const specialist = filterByInventoryType('Specialist')

    return {
      equipment,
      main,
      etc,
      specialist,
      costume
    }
  }

  private async getSubInventory(inventoryType: 'partnerWarehouse' | 'partnerInventory'): Promise<CharacterSubInventory> {
    const warehouseItemsIds = this.rawCharacter[inventoryType]?.map(({ itemInstance }) => itemInstance.itemVNum) || []
    const names = await Game.getItemsNames(warehouseItemsIds)
    const icons = await Game.getItemsIcons(warehouseItemsIds)
    const itemData = names.map((item, i) => Object.assign({}, item, icons[i]))

    const warehouse = this.rawCharacter[inventoryType]?.map(item => {
      const { name, iconId } = itemData.find(e => e.vnum == item.itemInstance.itemVNum)
  
      return new ItemFactory(item).buildItem({ name, iconId })
    }) || []

    return warehouse.sort((a: any, b: any) => a.slot - b.slot)
  }

  private getFaction(): CharacterFaction {
    const faction = this.rawCharacter.faction.toLowerCase()
  
    return {
      name: faction,
      img: null // faction != 'neutral' ? require(`@/assets/images/${faction}_wings.png`) : null
    }
  }

  /* private getMates(): Mate[] {
    const mates = this.rawCharacter.nosMates.map(mate => {
      return new MateFactory(mate).buildMate()
    })

    return mates
  } */

  private getReputation(): CharacterReputation {
    const thresholds = [
      0, // Beginner
      250, 500, 750, // Trainee
      1000, 2250, 3500, // The Experienced
      5000, 9500, 19000, // Battle Soldier
      25000, 40000, 60000, // Expert
      85000, 115000, 150000, // Leader
      190000, 235000, 285000, // Master
      350000, 500000, 1500000, // Nos
      2500000, 3750000, 5000000 // Elite
    ]
    const max = thresholds[thresholds.length - 1]
    const reputation = this.rawCharacter.reput
    const closestThreshold = reputation > max
      ? max // handle Ranking Reputation here in the future
      : thresholds.reduce((a, b) => reputation > b ? b : a)
    const closestThresholdIndex = thresholds.indexOf(closestThreshold)

    return {
      id: 31000 + closestThresholdIndex,
      value: reputation.toString().replace(SPLIT_COMMAS_REGEX, ",") || 0,
    }
  }

  private getTitles()/* : CharacterTitle[] */ {}

  private getStats(): CharacterStat[] {
    return Object
      .entries(this.rawCharacter.lifetimeStats)
      .map(([ name, value ]) => {
        return {
          name: camelToSnakeUpperCase(name),
          value
        } as CharacterStat
      })
      .filter(e => e.name != 'TOTAL_TIME_ONLINE')
  }

  private async getBonus() {
    const bonusVNums = this.rawCharacter.bonus.map(e => e.itemVnum)
    const icons =  await Game.getItemsIcons(bonusVNums)

    return this.rawCharacter.bonus?.map(bonus => {
      return {
        ...bonus,
        iconId: icons.find(icon => icon.vnum === bonus.itemVnum).iconId
      }
    })
  }
}