import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const availableLocalesCodes: string[] = []

const loadLocaleMessages = () => {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.yaml$/i)
  const messages = {}

  locales.keys().forEach(key => {
    const matched = key.match(/([a-z0-9]+)\./i)

    if (matched && matched.length > 1) {
      const locale = matched[1]

      if (locale === 'keys') return

      messages[locale] = locales(key)
      availableLocalesCodes.push(locale)
    }
  })

  return messages
}

export default new VueI18n({
  locale: localStorage.getItem('OGG_Locale') || 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
})

export const locales = availableLocalesCodes.map(localeCode => {
  return {
    locale: localeCode,
    img: require(`@/assets/images/flags/${localeCode}.png`),
    name: `LANGUAGE_${localeCode.toUpperCase()}`
  }
})