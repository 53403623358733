import {
  makeGetRequest,
  makePostRequest,
  makePutRequest
} from './makeRequest'

class ShopAPI {
  getCategories() {
    return makeGetRequest('/shop/categories')
  }
  
  getShopItems() {
    return makeGetRequest('/shop/packs')
  }
  
  buyItem(pack: BuyPackForm) {
    return makePostRequest(`/shop/packs/buy/${pack.characterId}`, [{
      id: pack.id,
      quantity: pack.quantity
    }])
  }
  
  getCouponsStash() {
    return makeGetRequest('/shop/coupons/stash')
  }
  
  redeemCouponOnStash(couponForm: CouponForm) {
    return makePostRequest('/shop/coupons/stash', couponForm)
  }
  
  redeemCoupon(coupon: string) {
    return makePutRequest(`/shop/coupons/receive/${coupon}`)
  }

  getFortuneWheelItems() {
    return makeGetRequest('/fortune-wheel')
  }

  getFortuneWheelSpins() {
    return makeGetRequest('/fortune-wheel-spins')
  }

  spinFortuneWheel(spinForm: SpinFortuneWheelForm) {
    return makePostRequest('/fortune-wheel/spin', spinForm)
  }
}

const Shop = new ShopAPI()

export default Shop