import { makeGetRequest, makePostRequest } from './makeRequest'
import { PaymentProviders } from '@/types/enums'

class PurchaseAPI {
  getCurrentPromotion() {
    return makeGetRequest('/shop/promotion')
  }

  getPurchaseAmounts() {
    return makeGetRequest('/payments/options')
  }

  getPaymentMethods() {
    return makeGetRequest('/shop/payment-methods')
  }

  getTransactionHistory(provider: PaymentProviders) {
    return makeGetRequest(`/payments/${provider.toLowerCase()}`)
  }

  makePayment(paymentForm: PaymentForm) {
    return makePostRequest(`/payments/${paymentForm.provider}`, {
      price: paymentForm.price
    })
  }

  cancelTransaction(provider: PaymentProviders, id: string) {
    return makePostRequest(`/payments/${provider}/expire/${id}`)
  }
}

const Purchase = new PurchaseAPI()

export default Purchase