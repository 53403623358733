// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const themeBreakpoints = {
  xl: 0,
  lg: 0,
  md: 0,
  sm: 0,
  xs: 0
}

const ascendAppConfig = {
  name: 'Olympus: Ascend',
  logo: require('@/assets/images/logo/logo-white.png'),
  wideLogo: require('@/assets/images/logo/logo-line-white.png')
}

const odysseyAppConfig = {
  name: 'Olympus: Odyssey',
  logo: require('@/assets/images/logo/logo-white-odyssey.png'),
  wideLogo: require('@/assets/images/logo/logo-line-white-odyssey.png')
}

// APP CONFIG
export const themeConfig = {
  app: {
    ...process.env.VUE_APP_OLYMPUS_SERVER_NAME === 'odyssey' ? odysseyAppConfig : ascendAppConfig,
    links: {
      rules: 'https://wiki.olympusgg.com/rules',
      support: 'https://discord.gg/U3pMwCg59Q',
      wiki: 'https://wiki.olympusgg.com'
    }
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // vertical, horizontal
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static', // static, sticky, hidden
    },
    customizer: true,
    enableScrollToTop: true,
  },
}
