import { checkIfGuest } from '../middlewares'

const AuthRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Auth/Login.vue'),
    meta: {
      guestAccess: true,
      layout: 'full',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Auth/Register.vue'),
    meta: {
      guestAccess: true,
      layout: 'full',
    },
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: () => import('@/views/Auth/ForgotPassword.vue'),
    meta: {
      guestAccess: true,
      layout: 'full'
    }
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: () => import('@/views/Auth/ResetPassword.vue'),
    meta: {
      guestAccess: true,
      layout: 'full'
    }
  }
]

AuthRoutes.forEach((route: any) => {
  route.beforeEnter = checkIfGuest
})

export default AuthRoutes