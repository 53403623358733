import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Account } from '@/api'

@Module({
  namespaced: true
})
export default class UserModule extends VuexModule {
  currentUser: User | null = null

  @Mutation
  setUser(user) {
    this.currentUser = user
  }

  @Mutation
  setUserDiscordInfos(discordInfos: DiscordAccount) {
    this.currentUser!.discordAccount = discordInfos
  }

  @Action({ commit: 'setUser' })
  async getUser() {
    return Account.getUser() || false
  }

  @Action({ commit: 'setUserDiscordInfos' })
  async refreshDiscordInfos(): Promise<DiscordAccount> {
    return Account.refreshDiscordInfos()
  }
}