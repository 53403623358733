import {
  makeGetRequest,
  makePostRequest
} from './makeRequest'

class GMAPI {
  getCharacter(searchCharacterForm: SearchCharacterForm) {
    return makePostRequest('/gm/characters', searchCharacterForm)
  }

  getCharactersByIds(ids: number[]) {
    return makePostRequest(`/gm/characters/ids`, ids)
  }

  getWarehouse(characterId) {
    return makeGetRequest(`/game/warehouse/${characterId}/inspect`)
  }

  addPunishment(addPunishmentLogForm: AddPunishmentLogForm) {
    return makePostRequest('/gm/punishments', addPunishmentLogForm)
  }

  addProofToPunishment(addPunishmentProofForm: AddPunishmentProofForm) {
    return makePostRequest('/gm/punishments/logs/add-proofs', addPunishmentProofForm)
  }

  getCharacterBazaar(characterId) {
    return makePostRequest(`/game/bazaar/gm/${characterId}`)
  }

  generateItemFromLogs(generateItemOptions: GenerateItemForm) {
    const { characterId, item } = generateItemOptions
  
    return makePostRequest(`/gm/create-item/${characterId}`, item)
  }
}

const GM = new GMAPI()

export default GM