import { checkOtherCharacters } from '@/router/middlewares'

const GameRoutes = [
  {
    path: '/character/:characterId',
    name: 'characters-id',
    component: () => import('@/views/Character.vue')
  }
]

GameRoutes.forEach((route: any) => {
  route.beforeEnter = checkOtherCharacters
})

export default GameRoutes