import {
  makeGetRequest,
  makePostRequest
} from './makeRequest'

class SGMAPI {
  getTransferableCharacters() {
    return makeGetRequest('/sgm/accounts/transfer')
  }

  transferCharacter(transferCharacterForm: TransferCharacterForm) {
    return makePostRequest('/sgm/accounts/transfer', transferCharacterForm)
  }

  transferCharacterBack(transferCharacterBackForm: TransferCharacterBackForm) {
    return makePostRequest('/sgm/accounts/transfer/back', transferCharacterBackForm)
  }
}

const SGM = new SGMAPI()

export default SGM