import {
  makeGetRequest,
  makePostRequest,
  makePutRequest
} from './makeRequest'
import { URLManager } from '@/services/Managers'

class AdminAPI {
  activateUser(id: string) {
    return makePostRequest(`/admin/users/${id}/activate`)
  }

  banUser(id: string, reason: string) {
    return makePostRequest(`/admin/users/${id}/ban`, { reason })
  }

  bulkBanUsers(ids: string[], reason: string) {
    return makePostRequest('/admin/users/bulk-ban', { ids, reason })
  }

  unbanUser(id: string, reason: string) {
    return makePostRequest(`/admin/users/${id}/unban`, { reason })
  }

  getAuthorities() {
    return makeGetRequest('/admin/users/authorities')
  }
  
  getUserById(id: string) {
    return makeGetRequest(`/admin/users/${id}`)
  }

  getUserCharacters(id: string) {
    return makeGetRequest(`/admin/account/${id}/characters`)
  }

  getUsers(userListPaginationFilters: UserListPaginationFilter) {
    const { authority, search, pageIndex, searchType } = userListPaginationFilters
    const params = { authority, search, pageIndex, searchType }
    const buildParams = URLManager.buildParams(params)
  
    return makeGetRequest(`/admin/users?${buildParams}`)
  }

  createCoupon(couponForm: CouponCreateForm) {
    return makePostRequest('/admin/shop/coupons/create', couponForm)
  }

  addPunishment(addPunishmentLogForm: AddPunishmentLogForm) {
    return makePostRequest('/gm/punishments', addPunishmentLogForm)
  }

  addProofToPunishment(addPunishmentProofForm: AddPunishmentProofForm) {
    return makePostRequest('/gm/punishments/logs/add-proofs', addPunishmentProofForm)
  }

  searchAltData(searchAltForm: SearchAltForm) {
    return makePostRequest('/gm/alts', searchAltForm)
  }

  searchAltsByMasterAccountId(accountId: string) {
    return makeGetRequest(`/gm/alts/master-account-id/${accountId}`)
  }

  searchAltsByCharacterId(characterId: string) {
    return makeGetRequest(`/gm/alts/character-id/${characterId}`)
  }

  searchAltsByIpAddress(ipAddr: string) {
    return makeGetRequest(`/gm/alts/ip-address/${ipAddr}`)
  }

  searchAltsByHardwareId(hwid: string) {
    return makeGetRequest(`/gm/alts/hardware-id/${hwid}`)
  }

  getCharacterBazaar(characterId) {
    return makePostRequest(`/game/bazaar/gm/${characterId}`)
  }

  getPaymentProviders() {
    return makeGetRequest('/root/shop/payment-methods/all')
  }

  enablePaymentProvider(method: string) {
    return makePutRequest(`/root/shop/payment-methods/enable/${method}`)
  }

  disablePaymentProvider(method: string) {
    return makePutRequest(`/root/shop/payment-methods/disable/${method}`)
  }

  addAccountNote(accountId: string, addNoteForm: AddNoteForm) {
    return makePostRequest(`/account-note/${accountId}`, addNoteForm)
  }

  getAccountNoteByAccountId(accountId: string) {
    return makeGetRequest(`/account-note/${accountId}`)
  }

  getAccountNotes() {
    return makeGetRequest(`/account-note`)
  }
}

const Admin = new AdminAPI()

export default Admin