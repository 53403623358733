import { checkIfAdmin } from '../middlewares/checkAuthority'

const AdminRoutes = [
  {
    path: '/user/:id',
    name: 'user',
    component: () => import('@/views/Admin/UserView.vue'),
    meta: {
      pageTitle: 'User View',
      breadcrumb: [
        { text: 'Administration', active: false },
        { text: 'User List', active: false },
        { text: 'User View', active: false }
      ]
    }
  },
  {
    path: '/user/:id/view/:characterId',
    name: 'admin-character-id',
    component: () => import('@/views/Character.vue'),
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Admin/UserList.vue'),
    meta: {
      pageTitle: 'User List',
      breadcrumb: [
        { text: 'Administration', active: false },
        { text: 'User List',  active: true }
      ]
    }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/Admin/News.vue'),
    meta: {
      pageTitle: 'News Management',
      breadcrumb: [
        { text: 'Administration', active: false },
        { text: 'News Management', active: true }
      ]
    }
  },
  {
    path: '/account-notes',
    name: 'account-notes',
    component: () => import('@/views/Admin/AccountNotes.vue'),
    meta: {
      pageTitle: 'AccountNotes',
      breadcrumb: [
        { text: 'Administration', active: false },
        { text: 'Account Notes', active: true }
      ]
    }
  },
  {
    path: '/coupons/create',
    name: 'coupons-create',
    component: () => import('@/views/Admin/CreateCoupon.vue'),
    meta: {
      pageTitle: 'Create Coupon',
      breadcrumb: [
        { text: 'Administration', active: false },
        { text: 'Create Coupon', active: true }
      ]
    }
  },
]

AdminRoutes.forEach((route: any) => {
  route.beforeEnter = checkIfAdmin
})

export default AdminRoutes