import {
    makeGetRequest,
    makePostRequest,
    makePatchRequest,
    makeDeleteRequest
  } from './makeRequest'

class NewsAPI {
  addNews(news) {
    return makePostRequest('/admin/news', news)
  }

  async getAllNews() {
    return makeGetRequest('/admin/news')
  }

  getNews(id: Number) {
    return makeGetRequest(`/admin/news/${id}`)
  }

  updateNews(news) {
    return makePatchRequest(`/admin/news/`, news)
  } 

  deleteNews(id: Number) {
    return makeDeleteRequest(`/admin/news/${id}`)
  }
}

const News = new NewsAPI()

export default News