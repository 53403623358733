import store from '@/store'
import { NavigationGuardNext, Route } from 'vue-router'

const checkIfGuest = async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  const isLogged = store.state.auth.isLogged

  if (isLogged) {
    next({ path: '/' })
  } else {
    next()
  }
}

export default checkIfGuest