import store from '@/store'

const fillStore = async () => {
  try {
    await Promise.all([
      // User
      store.dispatch('user/getUser'),
      store.dispatch('user/refreshDiscordInfos'),

      // Characters && Game Accounts
      store.dispatch('game/getCharacters', { commit: true }),
      store.dispatch('game/getGameAccounts'),

      // Game
      store.dispatch('game/getRankingTypes'),

      // Shop
      store.dispatch('shop/getShopCategories'),
      store.dispatch('shop/getShopItems'),
      store.dispatch('purchase/getPurchaseAmounts'),
      store.dispatch('purchase/getCurrentPromotion')
    ])
  } catch(e) {
    console.error('Error when filling store', e)
  }
}

export default fillStore