import app from './app'
import auth from './auth'
import game from './game'
import purchase from './purchase'
import shop from './shop'
import user from './user'

import { config } from 'vuex-module-decorators'
config.rawError = true

import { namespace } from 'vuex-class'

const AuthModule = namespace('auth')
const AppModule = namespace('app')
const GameModule = namespace('game')
const PurchaseModule = namespace('purchase')
const ShopModule = namespace('shop')
const UserModule = namespace('user')

export {
  AuthModule,
  AppModule,
  GameModule,
  PurchaseModule,
  ShopModule,
  UserModule
}

export default {
  app,
  auth,
  game,
  purchase,
  shop,
  user
}