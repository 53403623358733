import axios from 'axios'
import store from '@/store'
import { LocalStorageManager } from '@/services/Managers'

const refreshInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
})

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
})

let refreshQueue: any[] = []
let isRefreshing = false

const processQueue = (err, token: string | null) => {
  refreshQueue.forEach((p: any) => {
    err ? p.reject(err) : p.resolve(token)
  })

  refreshQueue = []
}

const errorInterceptor = err => {
  const originalRequest = err.config
  const accessToken = LocalStorageManager.getItem('OGG_AccessToken')
  const refreshToken = LocalStorageManager.getItem('OGG_RefreshToken')

  if (err.response.status != 401) {
    return Promise.reject(err)
  }

  if (!refreshToken) {
    return store.dispatch('auth/logout', true)
  }

  if (err.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        refreshQueue.push({ resolve, reject })
      })
      .then(token => {
        originalRequest.headers['Authorization'] = `Bearer ${token}`
        return axios(originalRequest)
      })
      .catch(err => {
        return Promise.reject(err)
      })
    }

    originalRequest._retry = true
    isRefreshing = true

    return new Promise((resolve, reject) => {
      refreshInstance.post('/refresh', {
        token: accessToken,
        refreshToken,
      })
      .then(({ data }) => {
        const accessToken = data.data.token

        LocalStorageManager.setItem('OGG_AccessToken', accessToken)
        LocalStorageManager.setItem('OGG_RefreshToken', data.data.refreshToken)

        axiosInstance.defaults.headers['Authorization'] = `Bearer ${accessToken}`
        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`
        processQueue(null, accessToken)
        resolve(axios(originalRequest))
      })
      .catch(err => {
        processQueue(err, null)
        reject(err)

        store.dispatch('auth/logout', true)
      })
      .finally(() => {
        isRefreshing = false
      })
    })
  }

  return Promise.reject(err)
}

axiosInstance.interceptors.response.use(undefined, err => errorInterceptor(err))

export default axiosInstance