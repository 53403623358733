import axios from '@/config/axios'
import { AxiosResponse } from 'axios'

const makeGetRequest = async (path: string): Promise<AxiosResponse | any> => {
  try {
    const { data } = await axios.get(path)

    return data
  } catch(e:any) {
    return e.response.data
  }
}

const makePostRequest = async (path: string, payload?: any): Promise<AxiosResponse | any> => {
  try {
    const { data } = await axios.post(path, payload)

    return data
  } catch(e:any) {
    console.log(e)
    return e.response.data
  }
}

const makePutRequest = async (path: string, payload?: any): Promise<any | boolean> => {
  try {
    const { data } = await axios.put(path, payload)

    return data
  } catch(e:any) {
    return e.response.data
  }
}

const makePatchRequest = async (path: string, payload?: any): Promise<any | boolean> => {
  try {
    const { data } = await axios.patch(path, payload)

    return data
  } catch(e:any) {
    return e.response.data
  }
}

const makeDeleteRequest = async (path: string): Promise<any | boolean> => {
  try {
    const { data } = await axios.delete(path)

    return data
  } catch(e:any) {
    return e.response.data
  }
}

export {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  makePatchRequest,
  makeDeleteRequest
}