export default class MateFactory {
  mate: any

  constructor(mate: any) {
    this.mate = mate
  }

  public buildMate(): Mate {
    const { mateType, attack, defence, level, npcMonsterVNum } = this.mate

    return {
      type: mateType,
      attack,
      defense: defence,
      level,
      vnum: npcMonsterVNum
    }
  }
}