import { checkIfRoot } from '../middlewares/checkAuthority'

const RootRoutes = [
  {
    path: '/root/pending-transactions',
    name: 'pending-transactions',
    component: () => import('@/views/Root/PendingTransactions.vue'),
    meta: {
      pageTitle: 'Pending Transactions',
      breadcrumb: [
        { text: 'Root', active: false }
      ]
    }
  },
  {
    path: '/root/settings',
    name: 'root-settings',
    component: () => import('@/views/Root/RootSettings.vue'),
    meta: {
      pageTitle: 'Root Settings',
      breadcrumb: [
        { text: 'Root', active: false }
      ]
    }
  },
  {
    path: '/root/gameversions',
    name: 'gameversions',
    component: () => import('@/views/Root/GameVersionManager.vue'),
    meta: {
      pageTitle: 'Game version management',
      breadcrumb: [
        { text: 'Root', active: false }
      ]
    }
  },
  {
    path: '/root/root-logs',
    name: 'root-logs',
    component: () => import('@/views/Root/RootLogs.vue'),
    meta: {
      pageTitle: 'Root Logs',
      breadcrumb: [
        { text: 'Root', active: false }
      ]
    }
  }
]

RootRoutes.forEach((route: any) => {
  route.beforeEnter = checkIfRoot
})

export default RootRoutes