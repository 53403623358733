import { checkIfSGM } from '../middlewares/checkAuthority'

const SGMRoutes = [
  {
    path: '/gm/character-transfer',
    name: 'character-transfer',
    component: () => import('@/views/GM/CharacterTransfer.vue'),
    meta: {
      pageTitle: 'Character Transfer',
      breadcrumb: [
        { text: 'GM Panel', active: false },
        { text: 'Character Transfer', active: false },
      ]
    }
  },
]

SGMRoutes.forEach((route: any) => {
  route.beforeEnter = checkIfSGM
})

export default SGMRoutes