import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VRipple from 'vue-ripple-directive'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import FeatherIcon from '../src/components/FeatherIcon.vue'

Vue.component(FeatherIcon.name, FeatherIcon)

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/clipboard'
import i18n from '@/i18n'

// Libs
Vue.use(BootstrapVue)
Vue.use(VueCompositionAPI)

// Mixins
import MiscMixin from './mixins/Misc'

Vue.mixin(MiscMixin)

// Directives
Vue.directive('ripple', VRipple)

// import styles
import '@/assets/scss/core.scss'
import '@/assets/scss/style.scss'

Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
