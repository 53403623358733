import store from '@/store'
import { NavigationGuardNext, Route } from 'vue-router'

export const checkIfRoot = async (to: Route, from: Route, next:NavigationGuardNext<Vue>) => {
  const { isRoot } = store.state.user.currentUser

  if (isRoot) {
    next()
  } else {
    next({ path: '/' })
  }
}

export const checkIfAdmin = async (to: Route, from: Route, next:NavigationGuardNext<Vue>) => {
  const { isAdmin } = store.state.user.currentUser

  if (isAdmin) {
    next()
  } else {
    next({ path: '/' })
  }
}

export const checkIfGM = async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  const { isGm } = store.state.user.currentUser

  if (isGm) {
    next()
  } else {
    next({ path: '/' })
  }
}

export const checkIfSGM = async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  const { isRoot, isAdmin, authority } = store.state.user.currentUser
  const isSgm = isRoot || isAdmin || authority === 'SuperGameMaster'

  if (isSgm) {
    next()
  } else {
    next({ path: '/' })
  }
}
