const AppRoutes = [
  {
    path: '/',
    redirect: 'profile',
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('@/views/App/Download.vue')
  },
  {
    path: '/leaderboards',
    name: 'leaderboards',
    component: () => import('@/views/App/Leaderboards.vue'),
    meta: {
      pageTitle: 'Leaderboards',
      breadcrumb: [
        { text: 'OlympusGG', active: false },
        { text: 'Leaderboards', active: false }
      ]
    }
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/App/Support.vue')
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import('@/views/Policies/TermsOfService.vue'),
    meta: {
      layout: 'full',
      guestAccess: true
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/Policies/PrivacyPolicy.vue'),
    meta: {
      layout: 'full',
      guestAccess: true
    }
  }
]

export default AppRoutes