import { Component, Vue } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { required, email, confirmed, min, max } from '@/services/Validation'

@Component({
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  }
})
export default class FormHelper extends Vue {
  passwordFieldType = 'password'
  required = required
  email = email
  confirmed = confirmed
  min = min
  max = max
  recaptcha = ''

  get passwordToggleIcon() {
    return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
  }

  togglePasswordVisibility() {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
  }
  
  setRecaptcha(token: string) {
    this.recaptcha = token
  }

  async handleEnterKey(key, callback) {
    if (key === 'Enter') {
      await callback()
    }
  }
}