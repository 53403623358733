












import { Component,  Vue, Watch } from 'vue-property-decorator'
import { $themeColors, themeBreakpoints, themeConfig } from '@/config/themeConfig'
import useAppConfig from '@/config/AppConfig'
import { useWindowSize, useCssVar } from '@vueuse/core'
import store from '@/store'
import fillStore from './services/fillStore'
import { AppModule, AuthModule } from './store/modules'

@Component({
  components: {
    LayoutVertical: () => import('@/components/Layout/LayoutDefault/LayoutDefault.vue'),
    LayoutFull: () => import('@/components/Layout/LayoutFull/LayoutFull.vue')
  }
})
export default class App extends Vue {
  @AuthModule.State isLogged
  @AppModule.Mutation UPDATE_WINDOW_WIDTH

  windowWidth = useWindowSize().width
  skinClasses = useAppConfig().skinClasses

  @Watch('windowWidth')
  onWindowWidthChange(val) {
    this.UPDATE_WINDOW_WIDTH(val)
  }

  get layout() {
    return this.$route.meta.layout === 'full' ? 'layout-full' : 'layout-vertical'
  }

  beforeCreate() {
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']
 
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    const { isRTL } = themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  }

  mounted() {
    const { skin } = useAppConfig()

    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    this.UPDATE_WINDOW_WIDTH(window.innerWidth)

    setInterval(async () => {
      if (this.isLogged) {
        fillStore()
      }
    }, 900000)
  }
}
