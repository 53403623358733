const buildFAQArray = (FAQType: string) => {
  return new Array(3)
    .fill({})
    .map((_, i) => {
      return {
        'question': `FAQ_${FAQType}_QUESTION_${i}`,
        'answer': `FAQ_${FAQType}_ANSWER_${i}`,
      }
    })
    .slice(1)
}

const camelToSnakeUpperCase = (str: string): string => str.replace(/[A-Z]/g, letter => `_${letter}`).toUpperCase()

const generateCoupon = () => {
  return `OGG-${(Math.random() * 1e32).toString(24).substring(2, 12).toUpperCase().match(/.{1,4}/g)?.join('-')}`
}

const formatNumber = (number: number) => {
  return Intl.NumberFormat('en', { notation: 'compact' }).format(number)
}

const fixFloatingValue = (number: number, unit: number = 1e2) => {
  return Math.round(number * unit) / unit
}

const pluralize = (word: string , number: number) => `${number} ${word}${number > 1 ? 's' : ''}`

export {
  buildFAQArray,
  camelToSnakeUpperCase,
  generateCoupon,
  formatNumber,
  fixFloatingValue,
  pluralize
}