import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Purchase } from '@/api'

@Module({
  namespaced: true
})
export default class PurchaseModule extends VuexModule {
  // Purchase OC
  currentAmountSelected: Nullable<number> = null
  purchaseAmounts: PurchaseAmount[] = []
  promotion: Nullable<Promotion> = null

  get selectedPurchaseAmount() {
    return this.purchaseAmounts.find(e => e.id === this.currentAmountSelected)
  }
  
  @Mutation
  setCurrentAmountSelected(id: number) {
    this.currentAmountSelected = id
  }

  @Mutation
  setPurchaseAmounts(purchaseAmounts) {
    this.purchaseAmounts = purchaseAmounts
  }

  @Mutation
  setPromotion(promotion: Promotion) {
    this.promotion = promotion
  }

  @Action({ commit: 'setPromotion' })
  async getCurrentPromotion(): Promise<Promotion> {
    const { title, bonusPercentage, startDate, endDate } = await Purchase.getCurrentPromotion()

    if (!title && !bonusPercentage && !startDate && !endDate) {
      return {
        enabled: false
      }
    }

    const formattedStartDate = new Date(startDate)
    const formattedEndDate = new Date(endDate)
    const promotionTitle = `${title}! +${bonusPercentage}% OC from ${formattedStartDate.toLocaleString().split(',')[0]} to ${formattedEndDate.toLocaleString().split(',')[0]}`

    return {
      enabled: true,
      value: bonusPercentage / 100,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      message: promotionTitle
    }
  }

  @Action({ commit: 'setPurchaseAmounts' })
  async getPurchaseAmounts(): Promise<any> {
    const data = await Purchase.getPurchaseAmounts()

    return data.map((e, i) => ({ id: i, ...e }))
  }
}
